import React from "react";
import { Col, Container, Row } from "reactstrap";
import { TopDownDivider, TopInnerShadow, TopUpDivider } from "./dividers";
import Subscribe from "./Subscribe";

export default function Footer() {
  return (
    <>
      <Subscribe />
      <div className="position-relative bg-green-stripes mt-0">
        <TopUpDivider />
        {/* <TopInnerShadow /> */}
        <Container tag="footer" className="pt-5 pb-3 text-light-on-green">
          <Row>
            <Col className="text-center">
              <small>
                © {new Date().getFullYear()} Tokyo Green. Website by{" "}
                <a href="https://www.hrunk.com/">Hrunk</a>
              </small>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
