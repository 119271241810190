import React from "react";
import { Col, Container, Row } from "reactstrap";
import { BottomDownDivider } from "./dividers";
import FacebookSvg from "../images/facebook-circle.svg";
import TwitterSvg from "../images/twitter-circle.svg";
import InstagramSvg from "../images/instagram-circle.svg";
import YoutubeSvg from "../images/youtube-circle.svg";
import styled from "styled-components";

const SocialIconLink = styled.a`
  display: inline-block;
  margin-left: 0.5rem;
`;

const SOCIAL_ICON_SIZE = "2rem";

export default function Header() {
  return (
    <div className="bg-green-stripes position-relative py-3">
      <BottomDownDivider />

      <Container>
        <Row className="align-items-center">
          <Col className="d-none d-lg-block" />
          <Col xs={12} lg={6}>
            <h1 className="display-3 font-fancy text-center text-night d-none d-md-block">
              Tokyo Green
            </h1>
            <h1 className="display-4 font-fancy text-center text-night d-block d-md-none">
              Tokyo Green
            </h1>
          </Col>
          <Col className="d-none d-lg-block">
            <Row className="align-items-center justify-content-center justify-content-lg-end">
              <SocialIconLink
                className="image-link"
                title="Our Twitter page"
                href="https://www.twitter.com/tokyogreenmusic"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterSvg
                  style={{
                    width: SOCIAL_ICON_SIZE,
                    height: SOCIAL_ICON_SIZE,
                  }}
                />
              </SocialIconLink>
              <SocialIconLink
                className="image-link"
                title="Our Facebook page"
                href="https://www.facebook.com/TokyoGreenMusic"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookSvg
                  style={{
                    width: SOCIAL_ICON_SIZE,
                    height: SOCIAL_ICON_SIZE,
                  }}
                />
              </SocialIconLink>
              <SocialIconLink
                className="image-link"
                title="Our Instagram"
                href="https://www.instagram.com/tokyogreenmusic"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramSvg
                  style={{
                    width: SOCIAL_ICON_SIZE,
                    height: SOCIAL_ICON_SIZE,
                  }}
                />
              </SocialIconLink>
              <SocialIconLink
                className="image-link"
                title="Our YouTube channel"
                href="https://www.youtube.com/channel/UCbs3FwnfoEq-5S4FlqB6Hmg"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeSvg
                  style={{
                    width: SOCIAL_ICON_SIZE,
                    height: SOCIAL_ICON_SIZE,
                  }}
                />
              </SocialIconLink>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
