import React from "react";
import { Col, Container, Row } from "reactstrap";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout({
  children,
  fluid,
  noContainer,
}: {
  children: any;
  fluid?: boolean;
  noContainer?: boolean;
}) {
  return (
    <>
      <Header />
      {noContainer ? (
        children
      ) : (
        <Container tag="main" fluid={fluid}>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      )}
      <Footer />
    </>
  );
}
