import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import FacebookSvg from "../images/facebook-circle.svg";
import TwitterSvg from "../images/twitter-circle.svg";
import InstagramSvg from "../images/instagram-circle.svg";
import YoutubeSvg from "../images/youtube-circle.svg";
import { BottomDownDivider, TopDownDivider, TopUpDivider } from "./dividers";

export default function Subscribe() {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "band.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="position-relative bg-grey-stripes pt-5">
        <TopUpDivider />
        <BottomDownDivider />

        <Container className="pt-5">
          <Row className="align-items-start">
            <Col className="text-center" xs={12}>
              <h1 className="font-fancy">Follow Us</h1>
              {/* <p>
                Sign up to be the first to know about news, new singles, and
                more
              </p>
              <Form>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Input
                      bsSize="lg"
                      placeholder="email address"
                      type="email"
                      name="email"
                      className="my-2"
                    />
                  </Col>
                  <Col xs="auto">
                    <Button size="lg" color="primary" className="my-2">
                      Sign Up
                    </Button>
                  </Col>
                </Row>
              </Form> */}
            </Col>
          </Row>
        </Container>
        <Container className="pb-5 pt-2">
          <Row className="align-items-center justify-content-center">
            <Col xs="auto">
              <a
                className="image-link"
                title="Our Twitter page"
                href="https://www.twitter.com/tokyogreenmusic"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterSvg style={{ width: "3rem", height: "3rem" }} />
              </a>
            </Col>
            <Col xs="auto">
              <a
                className="image-link"
                title="Our Facebook page"
                href="https://www.facebook.com/TokyoGreenMusic"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookSvg style={{ width: "3rem", height: "3rem" }} />
              </a>
            </Col>
            <Col xs="auto">
              <a
                className="image-link"
                title="Our Instagram"
                href="https://www.instagram.com/tokyogreenmusic"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramSvg style={{ width: "3rem", height: "3rem" }} />
              </a>
            </Col>
            <Col xs="auto">
              <a
                className="image-link"
                title="Our YouTube channel"
                href="https://www.youtube.com/channel/UCbs3FwnfoEq-5S4FlqB6Hmg"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeSvg style={{ width: "3rem", height: "3rem" }} />
              </a>
            </Col>
          </Row>
        </Container>
        <Container className="pb-5">
          <Row className="align-items-start">
            <Col>
              <Img fluid={placeholderImage.childImageSharp.fluid} />{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
