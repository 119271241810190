import React from "react";
import styled from "styled-components";

const FILL_COLOR = "#000";
const FILL_OPACITY = 0.3;

export function TopDownDivider() {
  return (
    <TopDownDividerContainer>
      <svg
        className="header-separator-svg header-separator-svg__waves_svg_separator"
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        fill={FILL_COLOR}
        fillOpacity={FILL_OPACITY}
        height="40"
        width="100%"
        viewBox="0 0 1200 30"
        preserveAspectRatio="none"
      >
        <path d="M0,0 C600,30 600,30 1200,0 L1200,0 L0,0"></path>
      </svg>
    </TopDownDividerContainer>
  );
}

const TopDownDividerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
`;

export function TopUpDivider() {
  return (
    <TopUpDividerContainer>
      <svg
        className="header-separator-svg header-separator-svg__waves_svg_separator"
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        fill={FILL_COLOR}
        fillOpacity={FILL_OPACITY}
        height="40"
        width="100%"
        viewBox="0 0 1200 30"
        preserveAspectRatio="none"
      >
        <path d="M0,30 C600,0 600,00 1200,30 L1200,0 L0,0"></path>
      </svg>
    </TopUpDividerContainer>
  );
}

const TopUpDividerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
`;

export function BottomDownDivider() {
  return (
    <BottomDownDividerContainer>
      <svg
        className="header-separator-svg header-separator-svg__waves_svg_separator"
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        fill={FILL_COLOR}
        fillOpacity={FILL_OPACITY}
        height="40"
        width="100%"
        viewBox="0 0 1200 30"
        preserveAspectRatio="none"
      >
        <path d="M0,0 C600,30 600,30 1200,0 L1200,30 L0,30"></path>
      </svg>
    </BottomDownDividerContainer>
  );
}

const BottomDownDividerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
`;

export function BottomUpDivider() {
  return (
    <BottomUpDividerContainer>
      <svg
        className="header-separator-svg header-separator-svg__waves_svg_separator"
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        fill={FILL_COLOR}
        fillOpacity={FILL_OPACITY}
        height="40"
        width="100%"
        viewBox="0 0 1200 30"
        preserveAspectRatio="none"
      >
        <path d="M0,30 C600,0 600,0 1200,30 L1200,30 L0,30"></path>
      </svg>
    </BottomUpDividerContainer>
  );
}

const BottomUpDividerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
`;

export function TopInnerShadow() {
  return <TopInnerShadowContainer />;
}

const TopInnerShadowContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
`;

export function BottomInnerShadow() {
  return <BottomInnerShadowContainer />;
}

const BottomInnerShadowContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
`;
